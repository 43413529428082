import Vue from 'vue';
import Router from 'vue-router';
import { auth } from '@/services/firebase';
import routes from './routes';
import { authGuard } from './guards';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

authGuard(router);

export default router;
