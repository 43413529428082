<template functional>
  <div :class="props.type" class="banner-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['success', 'info', 'warning', 'danger'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-container {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  padding: $spacing-025;
  font-size: $font-size--1;
  z-index: 9999;
}

.warning {
  background-color: $color-yellow-200;
}

.danger {
  background-color: $color-red-200;
}
</style>
