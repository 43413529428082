<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :height="props.height"
    :width="props.width"
    :aria-hidden="props.decorative"
    aria-labelledby="title"
  >
    <title id="title">{{ props.title }}</title>
    <path
      :fill="props.accentColor"
      d="M9 18.41l-2.3 2.3a1 1 0 0 1-1.4 0l-2-2A1 1 0 0 1 3 18V5c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v13a1 1 0 0 1-.3.7l-2 2a1 1 0 0 1-1.4 0L15 18.42l-2.3 2.3a1 1 0 0 1-1.4 0L9 18.4z"
    />
    <path
      :fill="props.mainColor"
      d="M7 7h10a1 1 0 0 1 0 2H7a1 1 0 1 1 0-2zm0 4h10a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2z"
    />
  </svg>
</template>

<script>
import iconProps from '@/mixins/iconProps';

export default {
  mixins: [iconProps],
};
</script>
