import firebase from 'firebase/app';
import { DbProduct, Rates } from '../../../types/database';
import { db } from '.';
import { convertBdproductsToClientProduct } from './models';

const getCategories = async () => {
  const snap = await db.ref('/kategorier').once('value');
  return Object.values(snap.val());
};

const getPrices = async (): Promise<Rates> => {
  const snap = await db.ref('/priser').once('value');
  return snap.val();
};

const getProducts = async (): Promise<DbProduct[]> => {
  const snap = await db.ref('/produkter').once('value');
  return Object.values(snap.val());
};

// This mathod returns a modefied array of all the products with some fields stripped out
// and some fields are combined into new fields
const getClientProducts = async () => {
  const data = await Promise.all([getPrices(), getProducts()]);
  const [rates, products] = data;

  return convertBdproductsToClientProduct(rates, products);
};

export { getCategories, getPrices, getProducts, getClientProducts };
