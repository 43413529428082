import { auth, db } from '@/services/firebase';

export default {
  namespaced: true,

  state: {
    cached: null,
    error: '',
  },

  mutations: {
    setUser(state, user) {
      state.cached = user;
    },

    setError(state, message) {
      state.error = message;
    },
  },

  actions: {
    async signIn({ commit, dispatch }, { userName, password }) {
      try {
        await auth.signInWithEmailAndPassword(userName, password);
        dispatch('fetchUser');
        commit('setError', '');
        return true;
      } catch (error) {
        const { code } = error;
        if (code === 'auth/invalid-email') {
          commit('setError', 'Ikke gyldig epostadresse');
        } else if (code === 'auth/user-not-found' || code === 'auth/wrong-password') {
          commit('setError', 'Feil bruker eller passord');
        } else {
          commit('setError', 'Det har oppstått en feil');
        }
        return false;
      }
    },

    async fetchUser({ commit }) {
      if (!auth.currentUser) return;

      const { uid } = auth.currentUser;
      const snapShot = await db.ref(`users/${uid}`).once('value');
      const user = {
        email: snapShot.val().email,
        name: snapShot.val().name,
        isAdmin: snapShot.val().isAdmin,
      };
      commit('setUser', user);
    },

    async resetPassword(context, email) {
      try {
        await auth.sendPasswordResetEmail(email);
        return true;
      } catch (error) {
        return false;
      }
    },

    async signOut({ commit }) {
      await auth.signOut();
      commit('setUser', null);
    },
  },

  getters: {
    getUser: state => state.cached,
  },
};
