<template>
  <div class="footer">
    Med forbehold om skrivefeil<br />
    v.{{ version }}
    &copy; 2020 Rørteknikk vvs as
  </div>
</template>

<script>
import { version } from '../../package.json';

export default {
  data() {
    return {
      version,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: auto;
  margin: 0 auto;
  font-size: $font-size--1;
  color: $color-gray-400;
}
</style>
