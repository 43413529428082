import { getCategories } from '@/services/firebase';
import { setLocalStorage, getLocalStorage } from './helpers/localStorage';

export default {
  namespaced: true,

  state: {
    all: [],
    active: 'Alle', // Default active category
  },

  mutations: {
    setCategories(state, categories) {
      state.all = categories;
    },
    setActive(state, category) {
      state.active = category;
    },
  },

  actions: {
    async fetchCategories({ commit, state }) {
      // check if state has categories already
      if (state.all.length !== 0) return;

      // if not run of to localstorage and se if there is something there
      const fromLocalStorage = getLocalStorage('kategorier');

      // if it is, commit that to state
      if (fromLocalStorage) {
        commit('setCategories', Object.values(fromLocalStorage));
      }

      // run of to firebase and grab the data
      const fromFirebase = await getCategories();

      // ? compare the data, this is todo - update function that sets a state property?

      // if data from firebase is newer, commit that to state
      commit('setCategories', fromFirebase);

      // commit the new data to localstorage
      setLocalStorage('kategorier', fromFirebase);
    },

    setActive({ commit }, category) {
      commit('setActive', category);
    },
  },

  getters: {
    //
  },
};
