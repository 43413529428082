<template functional>
  <button class="text-button" @click="listeners.click">
    <slot></slot>
  </button>
</template>

<script>
export default {
  //
};
</script>

<style lang="scss" scoped>
.text-button {
  text-decoration: underline;
}
</style>
