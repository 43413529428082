<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24.003"
    :height="props.height"
    :width="props.width"
    :aria-hidden="props.decorative"
    aria-labelledby="title"
    style="pointer-events: none"
  >
    <title id="title">{{ props.title }}</title>
    <g transform="translate(-2 -2)">
      <ellipse
        :fill="props.accentColor"
        cx="12"
        cy="12"
        rx="12"
        ry="12"
        transform="translate(2 2)"
      ></ellipse>
      <path
        :fill="props.mainColor"
        d="M3.66,20.466a6.2,6.2,0,0,1,5.381-3.113h9.918a6.2,6.2,0,0,1,5.381,3.113,12.423,12.423,0,0,1-20.68,0ZM14,14.882a4.941,4.941,0,1,1,4.959-4.941A4.95,4.95,0,0,1,14,14.882Z"
      ></path>
    </g>
  </svg>
</template>

<script>
import iconProps from '@/mixins/iconProps';

export default {
  mixins: [iconProps],
};
</script>
