export default {
  props: {
    accentColor: {
      type: String,
      default: '#CBD2D9',
    },
    mainColor: {
      type: String,
      default: '#616E7C',
    },
    height: {
      type: String,
      default: '24px',
    },
    width: {
      type: String,
      default: '24px',
    },
    decorative: {
      type: String,
      default: 'true',
      validator(value) {
        return ['true', 'false'].indexOf(value) !== -1;
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
};
