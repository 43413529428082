import { PriceRequest } from '@/services/firebase/PriceRequest';

export default {
  namespaced: true,

  state: {
    //
  },

  mutations: {
    //
  },

  actions: {
    async makeNewRequest({ rootGetters }, { requestText }) {
      /* interface ProductPriceRequest {
           requestText: string;
         } 
      */
      const { name, email } = rootGetters['user/getUser'];
      const request = new PriceRequest(requestText, name, email);
      await request.makeNewRequest();
    },
  },

  getters: {
    //
  },
};
