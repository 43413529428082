import Vue from 'vue';
import Vuex from 'vuex';

import categories from './modules/categories';
import products from './modules/products';
import cart from './modules/cart';
import user from './modules/user';
import requests from './modules/requests';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    categories,
    products,
    cart,
    user,
    requests,
  },

  state: {
    //
  },

  mutations: {
    //
  },

  actions: {
    //
  },

  getters: {
    //
  },
});
