import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
// import 'firebase/functions';
import config from './config';

// Initialize Firebase
firebase.initializeApp(config);

// initialize functions
// const functions = firebase.functions();
// functions.useFunctionsEmulator('http://localhost:5001');

// expose the functions
// const sendMail = functions.httpsCallable('helloMail');

// Firebase Authentication
const auth = firebase.auth();

// Firebase realtime database
const db = firebase.database();

export { auth, db };
