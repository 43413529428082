import VueRouter from 'vue-router';
import { auth } from '@/services/firebase';

const authGuard = (router: VueRouter) => {
  router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public);
    const isUser = !!auth.currentUser;

    // logged out
    if (!isUser && !isPublic) {
      return next({
        name: 'login',
        query: { redirect: to.fullPath },
      });
    }

    // dont show loginscreen if logged in
    if (isUser && isPublic) {
      return next('tilvalg');
    }

    // logged in
    if (isUser) {
      return next();
    }

    return next();
  });
};

export { authGuard };
