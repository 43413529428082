<template>
  <div class="banner shadow-2">
    <nav class="navbar">
      <router-link :to="{ name: 'tilvalg' }">
        <h1 class="logo">TILVALG</h1>
      </router-link>
      <ul v-if="user" class="navigation nav-inline">
        <li>
          <router-link
            v-tooltip="'Vis valgte tilvalg'"
            class="navigation__item"
            :to="{ name: 'valgteTilvalg' }"
          >
            <div :class="{ added: itemAdded }" class="cart-content">
              <!-- <span class="cart-content__count">{{ cartCount }} VALG</span> -->
              <span v-show="cartTotal" class="cart-content__price">{{
                cartTotal | currencyNOK
              }}</span>
            </div>
            <ReceiptIcon
              v-bind="{
                ...(cartCount
                  ? { mainColor: '#E3F8FF', accentColor: '#1992D4' }
                  : {}),
              }"
              decorative="true"
            />
            <div v-show="cartTotal" class="navigation__badge">
              {{ cartCount }}
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            class="navigation__item"
            v-tooltip="user.name"
            :to="{ name: 'profil' }"
          >
            <UserIcon decorative="false" :title="user.name" />
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import UserIcon from '@/components/graphic/UserIcon.vue';
import ReceiptIcon from '@/components/graphic/ReceiptIcon.vue';

export default {
  components: {
    UserIcon,
    ReceiptIcon,
  },
  data() {
    return {
      itemAdded: false,
    };
  },
  computed: {
    ...mapGetters({
      cartCount: 'cart/getItemCount',
      cartTotal: 'cart/getCartTotal',
    }),
    ...mapState({
      user: state => state.user.cached || '',
    }),
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === 'cart/addToCart' ||
        mutation.type === 'cart/updateQuantity'
      ) {
        this.itemAdded = true;
        setTimeout(() => {
          this.itemAdded = false;
        }, 500);
      }
    });
  },
};
</script>

<style scoped lang="scss">
.banner {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top: 4px solid $color-main-900;
  height: 80px;
  width: 100%;
  z-index: 999;
}

.navbar {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
}

.logo {
  background: url('../assets/logo.svg') 0px 0px/28px 28px no-repeat;
  color: $color-main-900;
  font-size: $font-size-2;
  font-weight: 700;
  line-height: 28px;
  margin-left: $spacing-300;
  padding: 2px 0 0 36px;
}

.navigation {
  margin-right: $spacing-300;
}

.navigation__item {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.navigation__badge {
  position: absolute;
  right: 12px;
  top: -9px;
  height: 18px;
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-red-400;
  border-radius: 10px;
  font-size: $font-size--2;
  color: #fff;
}

.cart-content {
  margin-right: $spacing-100;
  color: $color-gray-700;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.added {
  font-weight: 700;
  font-size: 1.2em;
}

.cart-content,
.added {
  transition: all 0.2s;
}

.cart-content__count {
  font-size: $font-size--2;
}

.cart-content__price {
  font-size: $font-size-1;
  font-weight: 600;
}

@media screen and (max-width: $tablet) {
  .logo {
    height: 40px;
    background: url('../assets/logo.svg') 16px 0px/16px 16px no-repeat;
    color: $color-main-800;
    font-size: $font-size--1;
    font-weight: 700;
    line-height: 54px;
    margin-left: $spacing-300;
    padding: 0;
  }
}
</style>
