import { getClientProducts } from '@/services/firebase';
import { setLocalStorage, getLocalStorage } from './helpers/localStorage';
import { alphabetSort } from './helpers/sort';

export default {
  namespaced: true,

  state: {
    all: [],
    priceYear: '2020',
    query: '',
  },

  mutations: {
    setPriceYear(state, year) {
      state.priceYear = year;
    },
    setProducts(state, products) {
      state.all = products;
    },
    searchQuery(state, newQuery) {
      state.query = newQuery;
    },
  },

  actions: {
    setPriceYear({ commit }, year) {
      commit('setPriceYear', year);
    },

    async fetchProducts({ commit, state }) {
      // check if state has categories already
      if (state.all.length !== 0) return;

      // if not, run of to localstorage and se if there is something there
      const fromLocalStorage = getLocalStorage('produkter');

      // if it is, commit that to state and sort the array so he order matches the menu
      if (fromLocalStorage) {
        commit('setProducts', alphabetSort(Object.values(fromLocalStorage)));
      }

      // run of to firebase service and grab the finished data
      // the data is modefied for storage in localstorage in firebase service
      const fromFirebase = await getClientProducts();

      // ? compare the data, this is todo

      // if data from firebase is newer, commit that to state and sort the array so he order matches the menu
      commit('setProducts', alphabetSort(fromFirebase));

      // commit the new data to localstorage
      setLocalStorage('produkter', fromFirebase);
    },

    searchQuery({ commit }, newQuery) {
      // const cleanQuery = newQuery.replace(/[^a-zA-Z0-9À-ž\s]/g, '');
      commit('searchQuery', newQuery);
    },
  },

  getters: {
    priceYears: state => ['2020', '2019'],

    // Filter on category
    filteredProducts: (state, getters, rootState) => {
      if (state.all.length === 0 || state.all === 'undefined') return [];

      const searchResult = getters.searchProduct;

      if (state.query) return searchResult;

      if (rootState.categories.active === 'Alle') return searchResult;

      return searchResult.filter(product => product.kategori === rootState.categories.active);
    },

    getQuery: state => state.query,

    // Search for product with query
    searchProduct: ({ query, all }) => {
      if (query === '') return all;
      const lowerCaseQuery = query.toLowerCase();

      return all.filter(
        product =>
          product.navn.toLowerCase().match(lowerCaseQuery) ||
          product.nrf.match(lowerCaseQuery) ||
          product.leverandor.toLowerCase().match(lowerCaseQuery)
      );
    },

    calculatePrice: state => productKey => {
      const matchedProduct = state.all.find(product => product._key === productKey);
      if (state.priceYear === '2019') return matchedProduct.totalpris_2019;
      return matchedProduct.totalpris_2020;
    },
    getName: state => productKey => {
      const matchedProduct = state.all.find(product => product._key === productKey);
      return matchedProduct.navn || '';
    },
    getNumber: state => productKey => {
      const matchedProduct = state.all.find(product => product._key === productKey);
      return matchedProduct.nrf || '';
    },
  },
};
