import { ClientProduct, DbProduct, Rates } from '../../../types/database';

function convertBdproductsToClientProduct(rates: Rates, products: DbProduct[]): ClientProduct[] {
  return products.map(product => {
    const { kostpris, paslag, arbeid, ...trimmedObject } = product;
    const totalpris_2020 = kostpris * paslag + arbeid * rates.timepris;
    return { totalpris_2020, ...trimmedObject };
  });
}

export { convertBdproductsToClientProduct };
