export default {
  namespaced: true,

  state: {
    all: [],
  },

  mutations: {
    addToCart(state, product) {
      state.all.push({ ...product });
    },
    updateQuantity(state, { productIndex, newQuantity }) {
      state.all[productIndex].quantity = newQuantity;
    },
    removeFromCart(state, productIndex) {
      state.all.splice(productIndex, 1);
    },
  },

  actions: {
    addItemToCart({ commit, dispatch, state }, { productId, quantity, standard }) {
      // Set quantity to negative 1 if the product is standard
      let addedQuantity = quantity;
      if (standard) addedQuantity = -1;
      // check if product already in cart
      const productInCart = state.all.find(item => item._key === productId);

      // if it is, get current quantity, add one and update, else add new to cart
      if (productInCart) {
        const newQuantity = productInCart.quantity + addedQuantity;
        dispatch('updateQuantity', { productId, newQuantity, standard });
      } else {
        commit('addToCart', {
          _key: productId,
          quantity: addedQuantity,
          standard,
        });
      }
    },
    updateQuantity({ commit, state }, { productId, newQuantity, standard }) {
      // get index of product to change quantity of
      const productIndex = state.all.findIndex(item => item._key === productId);
      const oldQuantity = state.all[productIndex].quantity;
      let updatedQuantity = newQuantity;

      if (newQuantity === 0) {
        updatedQuantity = oldQuantity * -1;
      }

      if (newQuantity === 0 && oldQuantity === 1) {
        updatedQuantity = -1;
      }

      // return if quantity is out of limit 1 - 100
      if (oldQuantity >= 100 || (oldQuantity < 1 && !standard) || oldQuantity < -100) {
        return;
      }

      // commit change
      commit('updateQuantity', { productIndex, newQuantity: updatedQuantity });
    },
    removeItemFromCart({ commit, state }, productId) {
      // get index of product to remove
      const productIndex = state.all.findIndex(item => item._key === productId);
      commit('removeFromCart', productIndex);
    },
  },

  getters: {
    getIndexOfCartItem: ({ all }) => productId => all.findIndex(item => item._key === productId),

    getCartItems: ({ all }) =>
      // sort to be implemented
      all,

    getItemCount: ({ all }) => {
      if (all.length === 0) return 0;
      return all.reduce((prev, curr) => Math.abs(prev) + Math.abs(curr.quantity), 0);
    },
    getCartTotal: ({ all }, getters, rootState, rootGetters) => {
      if (all.length === 0) return 0;
      return all.reduce(
        (prev, curr) => prev + curr.quantity * rootGetters['products/calculatePrice'](curr._key),
        0
      );
    },
  },
};
