<template>
  <div id="app">
    <notifications group="user" position="top center" :duration="5000" />
    <div class="main">
      <Navbar />
      <InfoBanner v-if="!showingUpdatedPrices" type="warning"
        >Viser gamle priser.
        <TextButton @click="changePriceYear(mostUpdatedPriceYear())"
          >Vis oppdaterte priser</TextButton
        >
      </InfoBanner>
      <router-view />
    </div>

    <div class="main-footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import '@/scss/main.scss';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import InfoBanner from '@/components/elements/InfoBanner.vue';
import TextButton from '@/components/elements/TextButton.vue';

export default {
  components: {
    Navbar,
    InfoBanner,
    TextButton,
    Footer,
  },
  computed: {
    ...mapState({
      priceYear: state => state.products.priceYear,
    }),
    ...mapGetters({
      priceYears: 'products/priceYears',
    }),
    showingUpdatedPrices() {
      return this.priceYear === this.mostUpdatedPriceYear();
    },
  },
  methods: {
    changePriceYear(year) {
      if (this.priceYear === year) {
        return false;
      }
      return this.$store.dispatch('products/setPriceYear', year);
    },
    mostUpdatedPriceYear() {
      const sortedYears = [...this.priceYears].sort();
      return sortedYears[sortedYears.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  display: grid;
  grid-template-rows: auto 100px;
}
</style>
