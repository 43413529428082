import Vue from 'vue';
import Notifications from 'vue-notification';
import VueMq from 'vue-mq';
import VModal from 'vue-js-modal';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import './registerServiceWorker';
import { auth } from '@/services/firebase';
import '@/filters/currencyNOK';

const VTooltip = require('v-tooltip');

Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(VModal);
Vue.use(VTooltip);

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    tablet: 767,
    desktop: Infinity,
  },
});

let app: Vue;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      created() {
        store.dispatch('user/fetchUser');
      },
      render: h => h(App),
    }).$mount('#app');
  }
});
