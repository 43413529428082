import { FirebasePriceRequest } from '../../../types/requests';
import { db, auth } from './index';

export class PriceRequest {
  private requestText: string;

  private name: string;

  private email: string;

  private uid = auth.currentUser?.uid;

  private path = `foresporsler/${this.uid}`;

  private _key = db
    .ref()
    .child(this.path)
    .push().key;

  constructor(requestText: string, name: string, email: string) {
    this.requestText = requestText;
    this.name = name;
    this.email = email;
  }

  async makeNewRequest() {
    await db
      .ref(`${this.path}/${this._key}`)
      .set({
        _key: this._key,
        requestText: this.requestText,
        responded: false,
        requestedAt: Date.now(),
        uid: this.uid,
        email: this.email,
        name: this.name,
      } as FirebasePriceRequest)
      .catch(() => {
        throw Error(this._key ?? '14-9');
      });
  }
}
