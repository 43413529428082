const standardOnTop = array => {
  return [...array].sort((a, b) => {
    if (a.standard && !b.standard) return -1;
    if (!a.standard && b.standard) return 1;
    return 0;
  });
};

export const alphabetSort = array =>
  [...standardOnTop(array)].sort((a, b) => {
    const productA = a.kategori.toUpperCase();
    const productB = b.kategori.toUpperCase();

    if (productA < productB) return -1;
    if (productA > productB) return 1;
    return 0;
  });

/*
I stedet for å sortere med en gang kan vi mappe hver kategori til sin egen
array, sortere disse også merge dem sammen igjen

Mulig det er bedre å laste inn kategoriene i hver sin array... Da må også products componenten skrives om.
*/

// function move(val, from, to) {
//   val.splice(to, 0, val.splice(from, 1)[0])
//   return val;
// }

// export const alphabetSort = array => {
//   const sortedByCategory = [...array].sort((a, b) => {
//     const productA = a.kategori.toUpperCase();
//     const productB = b.kategori.toUpperCase();

//     if (productA < productB) return -1;
//     if (productA > productB) return 1;
//     return 0;
//   })

//   let sortedArray = [...sortedByCategory];
//   let currentCategory = '';

//   sortedArray.forEach((product, index) => {
//     let indexOfNewCategory = 0;

//     // Update current category each time it changes and save the index
//     if (product.kategori !== currentCategory) {
//       indexOfNewCategory = index;
//       currentCategory = product.kategori;

//     }

//     // Change place of standard product if found
//     if (product.standard) {
//       move(sortedArray, index, indexOfNewCategory);
//     }

//     console.log(indexOfNewCategory);
//   })
//   return sortedArray;
// };
