import { RouteConfig } from 'vue-router';
import store from '@/store';

const LoginPage = () => import(/* webpackChunkName: "login" */ '@/views/LoginPage.vue');
const Login = () => import(/* webpackChunkName: "login" */ '@/components/Login.vue');

const RetrievePassword = () =>
  import(/* webpackChunkName: "forgot-pass" */ '@/components/RetrievePassword.vue');

const Tilvalg = () => import(/* webpackChunkName: "tilvalg" */ '@/views/Tilvalg.vue');
const VelgTilvalg = () => import(/* webpackChunkName: "tilvalg" */ '@/views/VelgTilvalg.vue');

const CartPage = () => import(/* webpackChunkName: "cart" */ '@/views/CartPage.vue');

const ProfilePage = () => import(/* webpackChunkName: "profile" */ '@/views/ProfilePage.vue');

const NotFound = () => import(/* webpackChunkName: "404" */ '@/views/NotFound.vue');

const routes: RouteConfig[] = [
  {
    path: '/',
    component: LoginPage,
    meta: { public: true },
    children: [
      {
        path: '',
        name: 'loginpage',
        component: Login,
      },
      {
        path: 'logg-inn',
        name: 'login',
        component: Login,
      },
      {
        path: 'glemt-passord',
        name: 'retrievePassword',
        component: RetrievePassword,
      },
    ],
  },
  {
    path: '/tilvalg',
    component: Tilvalg,
    children: [
      {
        path: '',
        name: 'tilvalg',
        component: VelgTilvalg,
      },
      {
        path: 'valgte-tilvalg',
        name: 'valgteTilvalg',
        component: CartPage,
      },
      {
        path: '/profil',
        name: 'profil',
        component: ProfilePage,
      },
    ],
  },
  {
    path: '/logg-ut',
    name: 'logOut',
    async beforeEnter(to, from, next) {
      await store.dispatch('user/signOut');
      return next({ name: 'login' });
    },
  },
  {
    path: '/*',
    name: 'notFound',
    component: NotFound,
  },
];

export default routes;
